import { useNavigate } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import { getShoppingLists, createShoppingList, updateShoppingList, deleteShoppingList } from '../api/shoppingList';
import { Link } from 'react-router-dom';


const Home = () => {
  const [lists, setLists] = useState([]);
  const [newListName, setNewListName] = useState('');
  const [editList, setEditList] = useState(null);

  useEffect(() => {
    fetchLists();
  }, []);

  const fetchLists = async () => {
    try {
      const fetchedLists = await getShoppingLists();
      setLists(fetchedLists);
    } catch (error) {
      console.error('Error fetching lists:', error);
    }
  };

  const handleCreateList = async () => {
    if (!newListName) return;

    try {
      const newList = await createShoppingList({ name: newListName });
      setLists([...lists, newList]);
      setNewListName(''); // Reset input
    } catch (error) {
      console.error('Error creating list:', error);
    }
  };

  const handleDeleteList = async (listId) => {
    try {
      await deleteShoppingList(listId);
      setLists(lists.filter(list => list.id !== listId));
    } catch (error) {
      console.error('Error deleting list:', error);
    }
  };

  const handleEditList = async (listId, updatedName) => {
    try {
      const updatedList = await updateShoppingList(listId, { name: updatedName });
      setLists(lists.map(list => (list.id === listId ? updatedList : list)));
      setEditList(null); // Exit edit mode
    } catch (error) {
      console.error('Error updating list:', error);
    }
  };

  return (
    <div>
      <h1>Your Shopping Lists</h1>
      
      {/* List Shopping Lists */}
      <ul>
        {lists.map((list) => (
          <li key={list.id}>
            <Link to={`/list/${list.id}`}>{list.name}</Link>
          </li>
        ))}
      </ul>

      {/* Create New List */}
      <div>
        <input
          type="text"
          placeholder="New List Name"
          value={newListName}
          onChange={(e) => setNewListName(e.target.value)}
        />
        <button onClick={handleCreateList}>Create List</button>
      </div>

      {editList && (
        <EditListForm list={editList} onSave={handleEditList} onCancel={() => setEditList(null)} />
      )}
    </div>
  );
};

export default Home;