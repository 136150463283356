import React from 'react';
import { useParams } from 'react-router-dom';
import { getListItems, getShoppingLists } from '../api/shoppingList';
import { useEffect, useState } from 'react';

function List() {
  const { id } = useParams();
  const [listName, setListName] = useState('');
  const [items, setItems] = useState([]);
  const [item, setItem] = useState('');

  useEffect(() => {
    getListName();
    getItems();
  }, []);

  const getListName = async () => {
    const lists = await getShoppingLists();
    const list = lists.find((list) => list.id == id);
    setListName(list.name);
  };

  const getItems = async () => {
    const response = await getListItems(id);
    setItems(response);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      addItem();
    }
  };
  
  const addItem = () => {
    if (item.trim() === '') return; // Prevent adding empty items
    //axiosInstance.post('/items', { name: item }).then(() => {
    //  setItems([...items, { name: item }]);
    //  setItem('');
    //});

  };

  return (
    <div>
      <h2>{listName}</h2>
      <input 
        value={item}
        onChange={e => setItem(e.target.value)}
        placeholder="Add an item"
        onKeyDown={handleKeyPress}
      />
      <button onClick={addItem}>Add</button>
      {items.length > 0 ? (
        items.map((item) => (
          <li key={item.id}>
            {item.name}
          </li>
        ))
      ) : (
        <li>No items found</li>
      )}
    </div>
  );
}

export default List;