import axiosInstance from "../axiosConfig";

const token = localStorage.getItem('token');

export const getShoppingLists = async () => {
    const response = await axiosInstance.get('/shopping-lists', { 
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
};
  
export const getListItems = async (listId) => {
    const response = await axiosInstance.get(`/shopping-lists/${listId}/items`, { 
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
};

export const createShoppingList = async (listData) => {
    const response = await axiosInstance.post('/shopping-lists', listData, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
};
  
export const updateShoppingList = async (listId, updatedData) => {
    const response = await axiosInstance.put(`/shopping-lists/${listId}`, updatedData, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
};
  
export const deleteShoppingList = async (listId) => {
    const response = await axiosInstance.delete(`/shopping-lists/${listId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
    return response.data;
};